@font-face{
    font-family: 'FrankRuh';
    src: url(../static/assets/fonts/FrankRuhlLibre-Regular.ttf);
}


*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow-x: hidden;
    line-height: 1.7;
    overflow: overlay;
}


/* .webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
} */

.loader-page {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    background:#161616;
}
:root {
    --circle-color: #989898;
}

#Lcontent {
    width: 100px;
    height: 100px;
    border: 1.5px solid var(--circle-color);
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

#circle{
    width: 92px;
    height: 92px;
    /* background: red; */
    animation: animate 2.5s linear infinite;
}

#circle:before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
}

@keyframes animate {
    100%{
        transform: rotate(360deg);
    }
}

#circle2{
    width: 60px;
    height: 60px;
    position: absolute;
    top: -20px;
    left: -20px;
    border: 1.5px solid var(--circle-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

#ball {
    width: 50px;
    height: 50px;
    /* background: red; */
    animation: animate2 1.5s linear infinite;
}

#ball:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
}

@keyframes animate2 {
    100%{
        transform: rotate(360deg);
    }
}



#container
{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    /* display: none; */
}

::-webkit-scrollbar {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 30px;
}

::-webkit-scrollbar-corner {
    border-radius: 2px;
    /* background:#000000; */
}

canvas
{
    display: block;
}

.wrap {
    /* margin: 0; */
    overflow: hidden;
}

/* Cinematic Black Bars */

.blackBarTop {
    width:100vw;
    height: 5%;
    background-color: rgb(0, 0, 0);
    position: fixed;
    transform: translateY(-90%);
    opacity: 0;
    visibility: visible;
}

.blackBarBottom {
    width:100vw;
    height: 5%;
    margin-top: 95vh;
    background-color: rgb(0, 0, 0);
    position: fixed;
    transform: translateY(90%);
    opacity: 0;
    visibility: visible;
}

.terminal {
    height: 100%;
    /* overflow: hidden !important; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25vh;
    bottom: 0%;
    right: 0%;
    left: 0.5%;
    width: 100%;
    /* overflow-y: scroll; */
}

#logo {
    position: absolute;
    width: 355px;
    display: block;
    opacity: 0;
    visibility: visible;
    /* margin-top: 5vh; */
    /* margin-left: 40%; */


}

#onButton {
    position: relative; 
    width:25px;
    display: block;
    margin-top: 25vh;
    margin-left: 0.0%;
    cursor: pointer;
    opacity: 0;
    visibility: visible;
    /* display: none; */
 
}

.section1{
    min-height: 100vh;
    width: 100vw;
    /* display: flex; */
    /* place-items: center; */
    /* border: 1px solid red; */
    padding: 100px;
    box-sizing: border-box;
}

.section2{
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    place-items: center;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    background-color: #292827f8;
    padding-top: 150px;
    padding-bottom: 150px;
    padding: 40px;
    /* margin-top: 15rem; */
    box-sizing: border-box;
    font-family: 'FrankRuh';
}

.bio-container{
    position: relative;
    max-width: 1280px;
    margin-top: 30px;
    left: 1vw;
    /* display:inline-flex; */
    padding-right: 10%;
    font-family: 'FrankRuh';

}


.sec2-title {
position: relative;
font-size: 44px;
text-transform: uppercase;
top: 17px;
font-weight: 500;
color:aliceblue;
padding-bottom: 20px;
display: inline-block;
font-family: 'FrankRuh';
}

.sec2-para {
line-height: 1.618;
max-width: 650px;
color:#b8b8b8;
font-family: 'FrankRuh';
font-size: 15px;
}

#rotated {
    color: #b8b8b80a;
    font-weight: 10;
    font-size: 28px;
    text-transform: uppercase;
    font-family: 'FrankRuh';
}

.rotatethis {
    position: absolute;
    z-index: -5;
    top: -13%;
    left: 24%;
    transform: translate(-50%, -50%);
    animation: rotating 20s linear infinite;
    display:block;
    font-family: 'FrankRuh';
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#c {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: block;
    /* opacity: 0.5; */
    z-index: 1; /*TURN THIS ON TO ACTIVATE 3D OBJECTS PER SECTION. (IT BREAKS THE RAIN WINDOW SOMEHOW)*/
    font-family: 'FrankRuh';
  }

.diagram {
    
    display: inline-block;
    width: 45em;
    height: 25em;
    left:-200em;
    /* border: 1px solid #ed9433; */
    /* z-index: 1; */
  }

  .right {
    display: center;
    align-items: center;
  }

  /* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
    .section2 {
      flex-direction: column;
    }
  }


/* === Portfolio Section === */


.work-section {
    display: grid;
    /* min-height: 100vh; */
    justify-content: center;
    align-items: center;
    height:auto;
    /* min-height: 100vh; */
    /* scroll-snap-align: start; */
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    font-family: 'FrankRuh';
}

.header-container2 {
    width:100vw;
    height: 10%;
    /* background-color: rgba(0, 0, 0, 0.39); */
    /* position: absolute; */
    font-family: 'FrankRuh';
}

.header2 {
    width: 100%;
    /* margin-bottom: 100px; */
    display: grid;
    place-items: center;
    text-align: center;
    font-family: 'FrankRuh';
}

.header2  h2{
    /* margin-bottom: 140px; */
    /* font-family: 'Raleway'; */
    /* position: relative; */
    width: 100%;
    /* line-height: 0px; */
    font-weight: 400;
    font-size: 35px;
    color:rgb(221, 221, 221);
    font-family: 'FrankRuh';
}


.portfolio-head p {
    line-height: 0px;
    margin-top: 10px;
    text-align: center;
    /* position: relative; */
    /* font-family: 'Raleway'; */
    color:rgb(202, 202, 202);
    font-weight: 200;
    font-size: 13px;
    font-family: 'FrankRuh';
}



.work__container {
    padding-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    justify-content: center;
    width: 100vw;
    gap: 1rem;
    padding-bottom: 2rem;
    height: auto;
    opacity:100;
    visibility: visible;
}


.work__filters {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .05rem;
    width: 100vw;
    margin-top: 2rem;
    font-family: 'FrankRuh';
}

.work__item {
    cursor: pointer;
    color:rgb(0, 0, 0);
    padding: .25rem .40rem;
    font-weight: 500;
    font-size: 12px;
    /* font-family: 'Raleway'; */
    /* border-radius: .5rem; */
    letter-spacing: 1px;
    font-family: 'FrankRuh';
}

.work__card {
    /* background-color: #6d6d6c; */
    position: relative;
    width: 400px;
    /* border-radius: 1rem; */
    font-family: 'FrankRuh';
} 

.work__img {
    /* border-radius: 1rem; */
    display: block;
    /* margin-bottom: .75rem; */
    /* max-width: 400px; */
    width: 100%;
    filter: grayscale(1);
    /* height: auto; */
    transition: 1s;
    font-family: 'FrankRuh';
}

.image__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: whitesmoke;
    /* font-family: 'Raleway'; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
    font-family: 'FrankRuh';
    font-weight: 50;
}

.image__overlay, h3 {
    font-family: 'FrankRuh';
    font-weight: 50;
    font-size: 20px;
}

.image__overlay, p {
    font-family: 'FrankRuh';
    font-size: 13px;
    font-weight: 50;
}

.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s;
    font-family: 'FrankRuh';
}

.image__overlay:hover {
    opacity: 1;
    cursor: pointer;
    font-family: 'FrankRuh';
}

.image__overlay:hover > * {
    transform: translateY(0);
}

.work__img-popup {
    margin-bottom: .75rem;
    max-width: 100%;
    width: auto;
    height: auto;
    transition: 1s;
    filter: grayscale(1);
    font-family: 'FrankRuh';
}

.work__card img:hover {
    filter:none;
}


.work__title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: .25rem;
    font-family: 'FrankRuh';
}

.work__button {
    width: max-content;
    color: rgb(88, 88, 88);
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: .25rem;
    font-family: 'FrankRuh';
}

.work__button:hover .work__icon {
    transform: translateX(.25rem);
}

.work__icon {
    font-size: 1rem;
    transition: .4s;
    font-family: 'FrankRuh';
}

/* Active Work Items  */

.active-work{
    background-color: #000000;
    color:#ffffff;
    font-family: 'FrankRuh';
}


 /* === WORK SECTION POP UP === */

.works__popup {
    position: fixed;
    inset: 0;
    background-color:#000000ce;
    width: 100vw; 
    /* height: 100vh; */
    padding: 2rem 0rem;
    display: grid;
    /* height: 100vh; */
    place-items: center;
    visibility: hidden; 
    opacity: 0;
    transition: .4s;
    z-index: 7000; /* maybe change? */
    overflow-y: scroll;
    font-family: 'FrankRuh';
}

.services__modal-content {
    position: relative;
    background-color: #000000da;
    padding: 4.5rem 1.5rem 2.5rem;
    font-family: 'FrankRuh';   
}

.services__modal-title, span {
    color: rgb(168, 168, 168);
    text-align: center;
    font-weight: 200;
    font-family: 'FrankRuh';

}

.services__modal-description {
    text-align: center;
    color: whitesmoke;
    font-family: 'FrankRuh';
}

.services__modal-title {
    font-size: 40px;
    /* font-family: 'Raleway'; */
    font-weight: 200;
    color: whitesmoke;
    margin-bottom: 1rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: 'FrankRuh';
}

.services__modal-description {
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: rgb(194, 194, 194);
    font-family: 'FrankRuh';
}

.services__modal-info {
    font-size: 14px;
    margin-bottom: 30px;
    color: rgb(168, 168, 168);
    font-family: 'FrankRuh';

}
.services__modal-list {
    display: grid;
    row-gap: .75rem;
    color: whitesmoke;
    font-family: 'FrankRuh';
}

.services__modal-item {
    display: flex;
    align-items: flex-start;
    column-gap: .5rem;
    font-family: 'FrankRuh';
}

.services__modal-icon {
    font-size: 1.5rem;
    color: whitesmoke;
    font-family: 'FrankRuh';
}

.services__modal-desc {
    font-size: 20px;
    /* font-family: 'Raleway'; */
    font-weight: 200;
    color: whitesmoke;
    margin-left: 7px;
    /* margin-bottom: 1rem; */
    font-family: 'FrankRuh';
}

.work__popup-info span{
    font-size: 14px;
    font-weight: 600;
    color: #b8b8b8;
    /* margin: 0px 0px 100px 100px; */
    font-family: 'FrankRuh';
}


.work__popup-info {
    font-size: 14px;
    /* margin: 0px 0px 100px 100px; */
    font-family: 'FrankRuh';
}

.services__modal-close {
    position: absolute;
    top: 1.0rem;
    right: 1.0rem;
    font-size: 1.2rem;
    color: rgb(158, 158, 158);
    cursor: pointer;
    font-family: 'FrankRuh';
}

/* Active Model */

.active-modal {
    opacity: 1;
    visibility: visible;
    font-family: 'FrankRuh';
}

/* CONTACT SECTION  */

.section3 {
    min-height: 100vh;
    width: 100vw;
    bottom: 5rem;
    /* display: flex;
    place-items: center; */
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    padding: 40px 40px;
    padding-top: 400px;
    padding-bottom: 400px;
    box-sizing: border-box;
    overflow: none;
    font-family: 'FrankRuh';
}

.section3 h2 {
    width: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-top: 5rem; */
    /* font-family: 'Raleway'; */
    font-weight: 400;
    font-size: 35px;
    color:rgb(221, 221, 221);
    line-height: 1.1;
    font-family: 'FrankRuh';
}

.section3 p {
    line-height: 20px;
    margin-top: 10px;
    text-align: center;
    /* position: relative; */
    /* font-family: 'Raleway'; */
    color:rgb(202, 202, 202);
    font-weight: 200;
    font-size: 13px;
    font-family: 'FrankRuh';
}

.section3 .media {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* transform: translate(-53%, -50%); */
    font-family: 'FrankRuh';
}

.section3 li {
    list-style: none;
    /* margin: 10px 30px; */
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;;
    font-size: 20px;
    padding: 40px 40px;
    color: #fff;
    transition: .5s;
    font-family: 'FrankRuh';
}

.section3 li:hover {
    color: #292929;
    transition: .5s;
    cursor: pointer;
    font-family: 'FrankRuh';
}